/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

function priorityNav() {

  var $nav = $('nav.greedy');
  var $btn = $('nav.greedy button');
  var $vlinks = $('nav.greedy .links');
  var $hlinks = $('nav.greedy .hidden-links');

  var numOfItems = 0;
  var totalSpace = 0;
  var breakWidths = [];

  // Get initial state
  $vlinks.children().outerWidth(function(i, w) {
    totalSpace += w;
    numOfItems += 1;
    breakWidths.push(totalSpace);
  });

  var availableSpace, numOfVisibleItems, requiredSpace;

  function check() {

    // Get instant state
    availableSpace = $vlinks.width() - 10;
    numOfVisibleItems = $vlinks.children().length;
    requiredSpace = breakWidths[numOfVisibleItems - 1];

    // There is not enought space
    if (requiredSpace > availableSpace) {
      $vlinks.children().last().prependTo($hlinks);
      numOfVisibleItems -= 1;
      check();
      // There is more than enough space
    } else if (availableSpace > breakWidths[numOfVisibleItems]) {
      $hlinks.children().first().appendTo($vlinks);
      numOfVisibleItems += 1;
    }
    // Update the button accordingly
    $btn.attr("count", numOfItems - numOfVisibleItems);
    if (numOfVisibleItems === numOfItems) {
      $btn.addClass('hidden');
      $('.bar').addClass('hidden');
    } else {
      $btn.removeClass('hidden');
      $('.bar').removeClass('hidden');
    }
  }

  $(document).on("scroll", function(){
  	if
      ($(document).scrollTop() > 150){
  	  $("body").addClass("scrolled");
      check();
  	}
  	else
  	{
  		$("body").removeClass("scrolled");
      check();
  	}
  });

  // Window listeners
  $(window).resize(function() {
    check();
  });

  $btn.on('click', function() {
    $hlinks.toggleClass('hidden');
    $('.bar').toggleClass('animate');
  });

  check();

}



function youTubeEmbed() {
  // START Fast & Agile YouTube Embed by Schoberg.net
  $(".youtube").each(function() {
    // Set the BG image from the youtube ID
    $(this).css('background-image', 'url(//i.ytimg.com/vi/' + this.id + '/hqdefault.jpg)');
    // Click the video div
    $(document).delegate('#' + this.id, 'click', function() {
      // Build embed URL
      var iframe_url = "//www.youtube.com/embed/" + this.id + "?autoplay=1&autohide=1";
      // Grab extra parameters set on div
      if ($(this).data('params')) {
        iframe_url += '&' + $(this).data('params');
      }
      // Build iframe tag
      var iframe = $('<iframe/>', {
          'allowfullscreen': 'allowfullscreen',
          'frameborder': '0',
          'src': iframe_url
        });
        // Replace the YouTube thumbnail with YouTube HTML5 Player
      $(this).append(iframe);
    }); // /click
  }); // /each video
  // END Fast & Agile YouTube Embed by Schoberg.net
}


function parallaxZoom() {
  var windowHeight = $(window).height();
  $(window).resize(function() {
    windowHeight = $(window).height();
  });

  var controller = new ScrollMagic.Controller();

  var scene2 = $('.advlax');
  scene2.each(function() {
    var sceneImg = $(this).find('.course_block--bgimage');
    var mascot = $(this).find('.mascot');
    var tweenIn2 = new TimelineMax();
    tweenIn2
    .from(sceneImg, 3, {
      scale: 1.5,
      force3D: true,
      autoAlpha: 0.5,
      transformOrigin: "50% 50%"
    })
    .from(mascot, 2, {
      scale: 0.5,
      force3D: true,
      transformOrigin: "50% 50%",
      y: 40,
      x: -120
    },'-=3');

    new ScrollMagic.Scene({
      triggerElement: this,
      triggerHook: 1,
      duration: $(window).height() * 2,
    })
    .setTween(tweenIn2)
    //.addIndicators()
    .addTo(controller);

  });
}


function parallaxScroll() {

  var scene = $('.advlax');
  var sceneHeight = $(window).height();

  $(window).resize(function() {
    sceneHeight = $(window).height();
  });

  var scrollMagicController = new ScrollMagic.Controller();

  scene.each(function() {
    var course_block = $(this).find('.course_block');
    var container = "#" + course_block.attr("id");

    var tweenIn = new TimelineMax();
    tweenIn.from(container + " > .course_block--bgimage", 1, {
      scale: 1.2,
      force3D: true,
      ease: Linear.easeNone,
      autoAlpha: 0
    }, 0);

    new ScrollMagic.Scene({
      triggerElement: container,
      triggerHook: 1,
      //offset: sceneHeight / 4,
      duration: sceneHeight / 2
    })
    .setTween(tweenIn)
    .addTo(scrollMagicController);


    var tweenOut = new TimelineMax();
    tweenOut.to(container + " > .course_block--bgimage", 1, {
      scale: 1.2,
      force3D: true,
      ease: Linear.easeNone,
      autoAlpha: 0
    }, 0);

    new ScrollMagic.Scene({
      triggerElement: container,
      triggerHook: 0,
      offset: sceneHeight / 2,
      duration: sceneHeight / 2
    })
    .setTween(tweenOut)
    .addTo(scrollMagicController);
  });

    // var i = 0;
    // scene.each(function() {
    //   i++;
    //
    //   // console.log(this);
    //   var tween = TweenMax.to("#scene" + i + " > .course_block--content", 1, {
    //     y: sceneHeight / 3,
    //     ease: Linear.easeNone,
    //     autoAlpha: 0
    //   });
    //
    //   new ScrollMagic.Scene({
    //     triggerElement: "#scene" + i
    //   })
    //   .setTween(tween)
    //   //.addIndicators()
    //   .addTo(scrollMagicController)
    // })
}


// function mapOpen(){
//   // If it's an iPhone..
//   if( (navigator.platform.indexOf("iPhone") != -1)
//     || (navigator.platform.indexOf("iPod") != -1)
//     || (navigator.platform.indexOf("iPad") != -1))
//     //window.open("maps://maps.google.com/maps/place/Tree+Adventures");
//     window.open("comgooglemaps://?q=Tree+Adventures");
//     //window.open("http://maps.apple.com/?q=Tree+Adventures");
//   else
//     window.open("http://maps.google.com/maps/place/Tree+Adventures/@-36.739709,174.4177863,17z/data=!3m1!4b1!4m5!3m4!1s0x6d0d0de1ad1f7feb:0xf6b74030ae67494b!8m2!3d-36.739709!4d174.419975");
// }

(function($) {


  // Use this variable to set up the common sand page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        priorityNav();
        //parallaxScroll();
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        //youTubeEmbed();
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'faqs': {
      init: function() {
        //faqs();
      }
    },
    'adventures': {
      init: function() {
        $("a[rel='m_PageScroll2id']").mPageScroll2id({
          highlightClass:"active"
        });
        parallaxZoom();
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
